import { render, staticRenderFns } from "./checkScore.vue?vue&type=template&id=46a08ef1&scoped=true"
import script from "./checkScore.vue?vue&type=script&lang=js"
export * from "./checkScore.vue?vue&type=script&lang=js"
import style0 from "./checkScore.vue?vue&type=style&index=0&id=46a08ef1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a08ef1",
  null
  
)

export default component.exports