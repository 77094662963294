<!-- 学分统计-查看 -->
<template>
    <div class="check">
        <div class="timeBox flex-align">
            时间:
            <el-date-picker class="time" v-model="value" size="mini" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="scoreBox">
            <span>王秀远学分统计:28</span>
            <div class="export">导出</div>
        </div>
        <div class="mid-bot">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column align="center" prop="num" label="序号"></el-table-column>
                <el-table-column align="center" prop="teaName" label="教师姓名"></el-table-column>
                <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
                <el-table-column align="center" prop="source" label="学分来源"></el-table-column>
                <el-table-column align="center" prop="score" label="学分"></el-table-column>
                <el-table-column align="center" prop="type" label="操作" fixed="right">
                    <template>
                        <el-button type="text" size="small" style="margin-left:10px" @click="checkScore">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="myPages flex-align-around">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[8, 10, 20]" :page-size="8"
                layout=" prev, pager, next,sizes" :total="40" background>
            </el-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: '',
                tableData: [{
                    num: 1,
                    teaName: '王秀远',
                    phone: '12312312312',
                    address: '新疆/和田地区/墨玉县',
                    school: '实验中学',
                    score: 155,
                    source: '作业提交'
                }],
            }
        },
        methods: {
            handleClick() {
                // this.$Api.Score.
            },
            handleSizeChange() {
                console.log('每页个数--')
            },
            handleCurrentChange() {
                console.log('tiaoye')
            },
        },
        mounted() {},
    };
</script>
<style lang='less' scoped>
    .check {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        background-color: #ffffff;
        box-shadow: 0px 2px 10px 0px #f5f8fa;
        border-radius: 8px;
        padding: 30px;

        .timeBox {
            margin-bottom: 10px;

            .time {
                margin-left: 10px;
            }
        }

        .scoreBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 14px;
                color: #333;
            }

            .export {
                cursor: pointer;
                font-size: 14px;
                color: #409EFF;
            }
        }

        .myPages {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
</style>